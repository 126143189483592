import { NANOID, ULID, UUID } from './id.patterns';
import { normalizeString } from './utils';

/**
 * Resolves an ID from a slug.
 *
 * It expects a dash (`-`) and one of the following patterns at the end of the slug:
 * - ULID
 * - UUID
 * - nanoid
 */
export function resolveIdFromSlug(
  slug: string[],
  maxLength: number = 400,
): string | null {
  if (slug.length === 0) {
    console.warn(`The slug: ${slug.join('/')} is empty.`);
    return null;
  }

  const segmentWithID = slug[slug.length - 1];

  if (!segmentWithID) {
    console.warn(`The id segment of the slug: ${slug.join('/')} is invalid.`);
    return null;
  }

  if (segmentWithID.length > maxLength) {
    console.warn(
      'Path segment exceeds the maximum allowed length for resolving id.',
    );
    return null;
  }

  const pattern = new RegExp(`(?:.*-)?(${ULID}|${NANOID}|${UUID})$`, 'i');
  const matchResults = segmentWithID.match(pattern);

  const match = matchResults?.[1];

  if (!match) {
    console.warn(`The segment: ${segmentWithID} does not contain a valid ID.`);
    return null;
  }

  return match;
}

/**
 * Converts a string to a slug.
 */
export function toSlug(text: string): string {
  const normalized = normalizeString(text);

  return normalized
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9-]/g, '')
    .replace(/-+$/, '');
}
