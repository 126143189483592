/**
 * Normalize string, removing accents and diacritics
 */
export function normalizeString(text: string): string {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function removeDashes(text: string): string {
  return text.replace(/-/g, ' ');
}
