'use client';

import type { RouterOutput } from '@noirproperties/functions/trpc/server';
import type { SiteLocale } from '@/i18n-config';

export function useCurrencyFormatter(locale = 'en', currency = 'EUR') {
  return (price: number) =>
    new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
}

type Property = NonNullable<RouterOutput['property']['get']['data']>;

// TODO: This could be exported directly from the API
// since it's only using the titles language schema
type PropertyLanguages = Property['titles'];

export function useLocalizedTextFormatter<T extends PropertyLanguages>(
  locale: SiteLocale,
) {
  return (texts: T): string => {
    if (locale === 'fa' || !(locale in texts)) {
      return texts['en']; // Default to English if 'fa' or the specified locale is not present since not supported by Kyero
    }
    return texts[locale];
  };
}
